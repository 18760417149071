import './sass/app.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpleSlider from './components/Slider';

function App() {
  return (
    <div className="App">
      <div className='container'>
        <div className='back-white'>
          <div className='main-block'>
            <div className='main-block-image'>
              <img className='photo' src='/images/me.jpg' alt='Гавриил' />
            </div>

            <div className='main-block-text'>
              <h1 className='name'>Гавриил Самарин</h1>
              <p className='address'>Россия, Серпухов</p>
              <p className='spec'>Студент</p>
            </div>
          </div>

          <div className='projects'>
            <h1 className='title'>Работы:</h1>

            <div className='slides'>
              <SimpleSlider />
            </div>
          </div>

          <div className='contacts'>
            <h1 className='title'>Контакты:</h1>

            <div className='icons'>
              <a className='icons-link' href='https://vk.com/gavriilsamarin'>
                <img className='icons-link-item' src='/images/contacts/vk.png' alt='ВК' />
              </a>

              <a className='icons-link' href='https://t.me/GavriilSamarin'>
                <img className='icons-link-item' src='/images/contacts/telegram.png' alt='Телеграм' />
              </a>

              <a className='icons-link' href="mailto:samaringavriil@yandex.ru">
                <img className='icons-link-item' src='/images/contacts/email.png' alt='Эл. почта' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
