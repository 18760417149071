import React, { Component } from "react";
import Slider from "react-slick";
import './slider.css';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      speed: 500,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000
    };
    return (
      <div className="main-slider">
        <Slider {...settings}>
          <div>
            <a href="https://история-народа.рф/"> 
              <img className="slide-image" src="/images/sites/5.png" /> 
            </a>
          </div>
          <div>
            <a href="http://nasha-istoriya.ru/">
              <img className="slide-image" src="/images/sites/1.png" /> 
            </a>
          </div>
          <div>
            <a href="https://виолетта-сергеевна.рф/">
              <img className="slide-image" src="/images/sites/2.png" /> 
            </a>
          </div>
          <div>
            <a href="https://павел-арустамян.рф/">
              <img className="slide-image" src="/images/sites/3.png" /> 
            </a>
          </div>
          <div>
            <a href="https://olesya-nikolaevna.tmweb.ru/">
              <img className="slide-image" src="/images/sites/4.png" /> 
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}